import React from "react";
import { FaCircle } from "react-icons/fa";
function BlockTimeline({ title, text, img,side }) {
  return (
    <li className={side}>
    <div class="timeline-badge">
      <a style={{marginLeft:"-1px"}}>
        <FaCircle color="#000"/>
      </a>
    </div>
    <div class="timeline-panel">
      <div class="timeline-heading">
        <h4>{title}</h4>
      </div>
      <div class="timeline-body">
        <img src={img}></img>
      </div>
    </div>
  </li>
  );
}

export default BlockTimeline;
