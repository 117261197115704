import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
function About() {
  return (
    <section className="about" id="about">
      <Container>
        <Row>
          <Col sm="6" className="mb-20">
            <img src="./img/about.jpg" alt="" />
          </Col>

          <Col sm="6">
            <h2>About Us</h2>
            <p>
              In Aries Builders, we stand out as leaders in the construction
              industry, offering customized solutions of exceptional quality.
              With a focus on innovation and sustainability, we apply our
              extensive expertise to residential, industrial, and custom
              projects, specializing in electrical solutions for large
              businesses.
            </p>
            <p>
              From the initial design to implementation and maintenance, our
              highly skilled team ensures optimal results, with a meticulous
              focus on every electrical detail. Based in South Arizona, we are
              committed to quality, safety, and excellence, adapting to the
              unique needs of each client in the electrical and construction
              fields.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;
