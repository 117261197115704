import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const NavbarComponent = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(prevShowMenu => !prevShowMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`navbar ${isSticky ? "sticky" : ""}`}>
        <div className={`navbar-content ${showMenu ? "show" : ""}`}>
          <a href="#home" onClick={closeMenu}>Home</a>
          <a href="#about" onClick={closeMenu}>About</a>
          <a href="#services" onClick={closeMenu}>Services</a>
          <a href="#projects" onClick={closeMenu}>Projects</a>
          <a href="#contactus" onClick={closeMenu}>Contact us</a>
        </div>
        <a href="#contactus" className="quote-container justify-content-end">
          <span>GET A QUOTE</span>
        </a>
        
        <div className="mobile-menu-toggle justify-content-end" onClick={toggleMenu} style={{marginLeft:"auto"}}>
          <div className={`bar ${showMenu ? "open" : ""}`}></div>
          <div className={`bar ${showMenu ? "open" : ""}`}></div>
          <div className={`bar ${showMenu ? "open" : ""}`}></div>
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
