import React from "react";
import { FaCheckCircle } from "react-icons/fa";

function ServiceP({Text}) {
  return (
    <div style={{display:"-webkit-box"}}>
      <FaCheckCircle style={{maxHeight:"100%"}}/>
      <p>{Text}</p>
    </div>
  );
}

export default ServiceP;
