import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
function CardServices({ title, text,img }) {
  return (
    <div className="card-service">
      <Row>
        <Row>
          <Col sm="6">
            <h3>{title}</h3>
          </Col>
          <Col sm="6">
            <img
              src={img}
              style={{ height: "150px", width: "auto" }}
            ></img>
          </Col>
        </Row>
        <Col>
          <p>{text}</p>
        </Col>
      </Row>
    </div>
  );
}

export default CardServices;
