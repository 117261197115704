import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="cardSocialMedia" style={{ display: "flex" }}>
      <a href={`https://www.linkedin.com/company/aries-general-builders/`} className="linkedin">
        <FaLinkedin />
      </a>
      <a href="https://www.facebook.com/profile.php?id=61556306185786" className="facebook">
      <FaFacebook />

      </a>
    </div>
  );
};

export default SocialMedia;
