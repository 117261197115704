import React, { useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import emailjs from "emailjs-com";

function ContactForm() {
  const [showModal, setShowModal] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      from_name: e.target[0].value,
      phone: e.target[1].value,
      reply_to: e.target[2].value,
      message: e.target[3].value,
    };

    const newErrors = {};

    if (formData.from_name.trim() === "") {
      newErrors.from_name = "Please enter your name.";
    } else {
      newErrors.from_name = "";
    }

    if (formData.message.trim() === "") {
      newErrors.message = "Please enter a message.";
    } else {
      newErrors.message = "";
    }

    if (!validateEmail(formData.reply_to)) {
      newErrors.reply_to = "Please enter a valid email address.";
    } else {
      newErrors.reply_to = "";
    }

    if (formData.phone.trim().length !== 10 || isNaN(formData.phone.trim())) {
      newErrors.phone = "Please enter a valid phone number (10 digits).";
    } else {
      newErrors.phone = "";
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    emailjs
      .send(
        "service_r06f0rd", // Reemplaza con tu Service ID para el correo al cliente
        "Cliente", // Reemplaza con tu Template ID para el correo al cliente
        formData,
        "m1qCUZoi4RuzAvVyu" // Reemplaza con tu User ID para el correo al cliente
      )
      .then(() => {
        emailjs
          .send(
            "service_r06f0rd", // Reemplaza con tu Service ID para el correo al usuario
            "Usuario", // Reemplaza con tu Template ID para el correo al usuario
            {
              ...formData,
              to_email: "javier.morales@ariesgeneralbuilders.com", // Reemplaza con tu dirección de correo
            },
            "m1qCUZoi4RuzAvVyu" // Reemplaza con tu User ID para el correo al usuario
          )
          .then(() => {
            handleOpenModal();
            setToSend({
              from_name: "",
              phone: "",
              reply_to: "",
              message: "",
            });
          })
          .catch((error) => {
            console.error("Error sending email to the user:", error);
          });
      })
      .catch((error) => {
        console.error("Error sending email to the client:", error);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="card-contact">
        <Col sm={"12"}>
          <input
            type="text"
            placeholder="Name"
            name="from_name"
            value={toSend.from_name}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.from_name}</p>
        </Col>
        <Col sm={"12"}>
          <input
            type="number"
            placeholder="Phone"
            maxLength={10}
            name="phone"
            value={toSend.phone}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.phone}</p>
        </Col>
        <Col sm={"12"}>
          <input
            type="mail"
            placeholder="Email"
            name="reply_to"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.reply_to}</p>
        </Col>
        <Col sm={"12"}>
          <textarea
            type="text"
            placeholder="Message"
            name="message"
            value={toSend.message}
            onChange={handleChange}
          ></textarea>
          <p className="error-msj">{errors.message}</p>
        </Col>
        <Col sm={"12"}>
          <button
            type="submit"
            className="primary btn"
            style={{ width: "100%" }}
          >
            Get a quote
          </button>
        </Col>
      </form>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Email sent successfully!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you very much for your preference!
          <br></br>
          <br></br>
          Someone from our team will contact you shortly.
        </Modal.Body>

        <Modal.Footer>
          <button
            className="primary btn"
            onClick={handleCloseModal}
            style={{ width: "100%" }}
          >
            Got it
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactForm;
