import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";

function Solution() {
  return (
    <section className="solution mt-5">
      <Container>
        <Row>
          <Col className="card-solution card-one">
            <img src="img/icons/sustainability.png"></img>
            <div className="line-b"></div>
            <h2>Sustainability</h2>
            <p>
              We prioritize responsible construction, adopting eco-friendly
              practices and materials to reduce our environmental footprint. Our
              constant pursuit of sustainable methods drives us to promote a
              more eco-efficient future.
            </p>
          </Col>
          <Col className="card-solution card-three">
            <img src="img/icons/tech.png"></img>
            <div className="line-b-red"></div>
            <h2>Latest Tech</h2>
            <p>
            We stay at the forefront by incorporating technological innovations in every phase of our projects. From cutting-edge design tools to intelligent systems like automation, we offer advanced and efficient solutions.
            </p>
          </Col>
          <Col className="card-solution card-two">
            <img src="img/icons/time.png"></img>
            <div className="line-b"></div>
            <h2>Project on time</h2>
            <p>
            Punctuality is our guarantee. With meticulous project management and a highly competent team, we meet and surpass agreed-upon deadlines without compromising quality or safety.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Solution;
