import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import NavbarComponent from "../components/navbarComponent";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import BannerTop from "../components/BannerTop";
function Header() {
  return (
    <div className="banner" id="home">
      
        <BannerTop/>

      <NavbarComponent />
      <div className="banner-img" style={{ height: "100vh" }}>
        <Container className="header mx-auto">
          <Col className="col-md-6 col-sm-12">
            <h1>Building with Energy</h1>
            <p>
              We transform ideas into currents of progress. We connect vision
              with power, constructing tomorrow with energy and dedication.
            </p>
            <div className="btn-area pull-left">
              <a className="primary btn" href="#services">
                our services
              </a>
              <a className="secondary btn" href="#contactus">
                Contact us
              </a>
            </div>
          </Col>
        </Container>
      </div>
    </div>
  );
}
export default Header;
