import React, { useState, useEffect } from "react";
import Header from "../sections/header";
import Solution from "../sections/solution";
import Services from "../sections/services";
import Proyect from "../sections/proyect";
import About from "../sections/about";
import CotactUs from "../sections/contactus";
import Footer from "../sections/footer";
import SocialMedia from "../components/SocialMedia";
import HowWeWork from "../sections/how_we_work";
import { Spinner } from "react-bootstrap";
import FooterCopy from "../components/FooterCopyRigth";

function MainPage() {
  const [loading, setLoading] = useState(true); // Estado para controlar la carga

  useEffect(() => {
    // Simula un retraso de 1.5 segundos para simular la carga de datos
    const timeout = setTimeout(() => {
      setLoading(false); // Una vez que termina el tiempo, establece loading en falso
    }, 800);

    // Limpia el timeout en caso de que el componente se desmonte antes de que finalice la carga
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`main-page ${loading ? 'hidden' : 'fade-in'}`}> {/* Aplica clases de animación */}
      {loading ? ( // Muestra el spinner si la página está cargando
        <div className="text-center mt-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <SocialMedia />
          <Header />
          <Solution />
          <About />
          <Services />
          <HowWeWork />
          <Proyect />
          <CotactUs />
          <Footer />
          <FooterCopy/>
        </>
      )}
    </div>
  );
}

export default MainPage;
