import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import ServiceP from "../components/service-p";
import CardServices from "../components/card-services";
function Electric() {
  return (
    <div className="p-service p-service-electric ">
      <ServiceP Text="Design Build" />
      <ServiceP Text="Electric Design" />
      <ServiceP Text="Controls Design" />
      <ServiceP Text="Electrical Inspections" />
      <ServiceP Text="Troubleshooting" />
      <ServiceP Text="Surge Protection" />
      <ServiceP Text="Water Well Controls" />
      <ServiceP Text="Photovoltaic Systems" />
      <ServiceP Text="Service Upgrades" />
      <ServiceP Text="LED Retrofits" />
      <ServiceP Text="Cable tray installation" />
    </div>
  );
}
function Residencial() {
  return (
    <div className="p-service p-service-residencial">
      <ServiceP Text="Foundation" />
      <ServiceP Text="Concrete Floor" />
      <ServiceP Text="Structural" />
      <ServiceP Text="Interior Rough-In" />
      <ServiceP Text="Roofing & Exterior" />
      <ServiceP Text="Stucco Exterior" />
      <ServiceP Text="Kitchen" />
      <ServiceP Text="Bathroom" />
      <ServiceP Text="Bedroom" />
      <ServiceP Text="Living Room" />
    </div>
  );
}
function Industrial() {
  return (
    <div className="p-service p-service-industrial">
      <ServiceP Text="Field wiring data" />
      <ServiceP Text="Communications" />
      <ServiceP Text="Ethernet" />
      <ServiceP Text="Modbus" />
      <ServiceP Text="Profibus" />
      <ServiceP Text="Motor controls, DCS, PLC , Analog and Digital instrumentation" />
      <ServiceP Text="Commissioning" />
      <ServiceP Text=" Quality Control Management" />

      <p>Successfully executing the electrical and instrumentation, recommissioning, commissioning, and start-up activities for all disciplines.</p>
    </div>
  );
}

function Services() {
  return (
    <section className="services" id="services">
      <Container>
        <Row>
          <span className="centered">Our Services</span>
          <h2 className="centered">Quality Services</h2>
        </Row>

        <Row className="card-services-container">
          <Col className="col-sm-12 col-md-4 mb-5">
            <CardServices
              title={"Electric"}
              text={<Electric />}
              img="././img/icons/Electric.png"
            />
          </Col>
          <Col className="col-sm-12 col-md-4 mb-5">
            <CardServices
              title={"Industrial"}
              text={<Industrial />}
              img="././img/icons/Industrial.png"
            />
          </Col>
          <Col className="col-sm-12 col-md-4 mb-5">
            <CardServices
              title={"Residential"}
              text={<Residencial />}
              img="././img/icons/Residencial.png"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
