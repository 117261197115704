import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import BlockTimeline from "../components/block-timeline";

function HowWeWork() {
  return (
    <section className="timeline-section" id="about">
      <Container>
        <Row style={{ marginBottom: "20px" }}>
          <span className="centered">Our process to build</span>

          <h2 className="centered mb-1">How do we build it?</h2>
        </Row>
        <Row>
          <Col sm="12">
            <ul class="timeline">
              <BlockTimeline
                title={"FOUNDATION​"}
                side={""}
                img={"img/timeline/fundation.png"}
              />
              <BlockTimeline
                title={"CONCRETE FLOOR​"}
                side={"timeline-inverted"}
                img={"img/timeline/concrete_floor.png"}
              />
              <BlockTimeline title={"STRUCTURAL"} side={""} img={"img/timeline/structural.png"}/>
              <BlockTimeline
                title={"INTERIOR ROUGH-IN​"}
                side={"timeline-inverted"}
                img={"img/timeline/interior.png"}
              />
              <BlockTimeline title={"ROOFING"} side={""} img={"img/timeline/roofing.png"}/>
              <BlockTimeline title={"EXTERIOR​"} side={"timeline-inverted"} img={"img/timeline/exterior.png"}/>

              <li class="clearfix no-float"></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HowWeWork;
