import MainPage from "./pages/main-page";
import NavbarComponent from "./components/navbarComponent";
function App() {
  return (
    <div>
      <MainPage />
    </div>
  );
}

export default App;
