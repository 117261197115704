import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Row, Modal } from "react-bootstrap";

function Projects() {
  const allInitialImages = [

    "r1",
    "r2",
    "r3",
    "r4",
   
  ];

  const allImagesRecidencial = [
    "r1",
    "r2",
    "r3",
    "r4",
    "r5",
    "r6",
    "r7",
    "r8",
    "r9",
    "r10",
  ];

  const [showMore, setShowMore] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleShowMore = () => {
    setShowMore(!showMore);
    setSelectedImage(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const allImages = showMore
    ? [...allImagesRecidencial]
    : allInitialImages;

  return (
    <section className="projects" id="projects">
      <Container>
        <Row className="centered mb-30">
          <span>Exploring our</span>
          <h2>project PORTFOLIO</h2>
        </Row>
        <Row className="mb-30">
          <Col>
            <div className="projects-container centered ">
              {allImages.map((image, index) => (
                <img
                  key={index}
                  className="image"
                  src={`img/projects/${image}.jpg`}
                  alt=""
                  style={{ display: "block" }}
                  onClick={() => handleImageClick(image)}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Row className="centered" style={{ marginTop: "50px" }}>
          <Col>
            <a className="primary btn" onClick={handleShowMore}>
              {showMore ? "Show less" : "Show more"}
            </a>
          </Col>
        </Row>
        <Modal show={selectedImage !== null} onHide={handleCloseModal} centered size="xxl">
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body style={{padding:"0"}}>
            {selectedImage && (
              <img
                src={`img/projects/${selectedImage}.jpg`}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </section>
  );
}

export default Projects;