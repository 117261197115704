import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BannerTop = () => {
  return (
    <Container>
      <div className="bannerTop">
        <div className="logo-container d-flex">
          <img
            style={{ height: "auto", width: "400px" }}
            src="img/logo/aries.png"
          ></img>
          <div
            className="d-flex contact-header"
            style={{ alignItems: "center", marginLeft: "50px" }}
          >
            <div>
              <span>ROC 346534</span>
              <br></br>
              <span>ROC 35<span style={{marginRight:"2px",marginLeft:"2px"}}>1</span><span style={{marginRight:"2px",marginLeft:"2px"}}>1</span>35</span>
            </div>
          </div>
        </div>
        <div className="contact-header">
          <img
            style={{ height: "auto", width: "250px", padding: "10px" }}
            src="img/logo/sahba.jpg"
          ></img>
        </div>
      </div>
    </Container>
  );
};

export default BannerTop;
